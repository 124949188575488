import React from 'react';
import PropTypes from 'prop-types';
import { Edit } from 'lucide-react';
import profilePicture from '../../assets/images/test-user.png';
import '../../styles/components/Profile.scss';

const ProfileInfo = ({ profile, onEdit }) => (
  <div className="profile-card">
    <img className="profile-image" src={profilePicture} alt="Profile" />
    <div className="profile-details">
      <div className="form-field">
        <label>Username</label>
        <p>{profile.username}</p>
      </div>
      <div className="form-field">
        <label>Email</label>
        <p>{profile.email}</p>
      </div>
    </div>
    <div className="form-actions">
      <button onClick={onEdit} className="edit-button">
        <Edit size={18} />
        <span>Edit Profile</span>
      </button>
    </div>
  </div>
);

ProfileInfo.propTypes = {
  profile: PropTypes.shape({
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default ProfileInfo;