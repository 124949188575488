// src/components/Dashboard.js

import React, { useContext, useEffect, useState } from 'react';
import axiosInstance from '../api/axiosConfig';
import { AuthContext } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { Activity, Utensils, TrendingUp, Award } from 'lucide-react';
import '../styles/pages/DashboardPage.scss';

const DashboardPage = () => {
  const { auth } = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');

  // Fetch user-specific data if needed
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get(`/users/${auth.user.id}`);
        setUserData(response.data);
      } catch (err) {
        setError('Failed to fetch user data.');
      }
    };

    if (auth.isAuthenticated) {
      fetchUserData();
    }
  }, [auth]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!auth.isAuthenticated || !userData) {
    return <div>Loading...</div>; // Or a spinner/loading component
  }

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <h1>Welcome, {auth.user.username}!</h1>
        <p>Your personalized health tracking dashboard.</p>
        <Link to="/profile" className="profile-link">View Profile</Link>
      </header>

      <section className="dashboard-features">
        <div className="feature-card">
          <Activity size={48} />
          <h2>Track Your Workouts</h2>
          <p>Log your exercises, sets, reps, and see your progress over time.</p>
          <Link to="/exercise" className="cta-button">Start Exercising</Link>
        </div>
        <div className="feature-card">
          <Utensils size={48} />
          <h2>Monitor Your Diet</h2>
          <p>Record your meals and get personalized nutrition insights.</p>
          <Link to="/nutrition" className="cta-button">Track Nutrition</Link>
        </div>
        <div className="feature-card">
          <TrendingUp size={48} />
          <h2>Visualize Progress</h2>
          <p>See your fitness journey with detailed stats and charts.</p>
          <Link to="/progress" className="cta-button">View Progress</Link>
        </div>
        <div className="feature-card">
          <Award size={48} />
          <h2>Achieve Your Goals</h2>
          <p>Set targets and get recommendations to reach your fitness objectives.</p>
          <Link to="/goals" className="cta-button">Set Goals</Link>
        </div>
      </section>

      {/* Add more personalized sections as needed */}
    </div>
  );
};

export default DashboardPage;
