// src/components/LogIn.js

import React, { useState, useContext } from 'react';
import axiosInstance from '../../api/axiosConfig';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import '../../styles/pages/LoginPage.scss';

const LogIn = () => {
  const navigate = useNavigate();
  const { verifyAuth } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState([]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post('/users/login', formData);
      await verifyAuth(); // Update authentication state
      navigate('/dashboard'); // Redirect to dashboard
    } catch (error) {
      if (error.response && error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else {
        setErrors([{ msg: error.response?.data?.message || 'Error logging in.' }]);
      }
    }
  };

  return (
    <div className="auth-page">
      <form className="auth-form" onSubmit={handleSubmit}>
        <h2>Log In</h2>
        {errors.length > 0 && (
          <ul className="error-messages">
            {errors.map((err, index) => (
              <li key={index}>{err.msg}</li>
            ))}
          </ul>
        )}
        <div className="form-field">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder="Enter your email"
          />
        </div>
        <div className="form-field">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            placeholder="Enter your password"
          />
        </div>
        <button type="submit" className="auth-button">Log In</button>
        <p className="redirect-text">
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </p>
      </form>
    </div>
  );
};

export default LogIn;
