// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axiosInstance from '../api/axiosConfig';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    user: null,
    loading: true,
  });

  // Function to verify authentication status
  const verifyAuth = async () => {
    try {
      const response = await axiosInstance.get('/users/verify-auth');
      setAuth({
        isAuthenticated: true,
        user: response.data.user,
        loading: false,
      });
    } catch (error) {
      setAuth({
        isAuthenticated: false,
        user: null,
        loading: false,
      });
    }
  };

  useEffect(() => {
    verifyAuth();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth, verifyAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
