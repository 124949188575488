import axios from 'axios';
import { API_BASE_URL } from '../config';

const instance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // This ensures cookies are sent with every request
});

// Fetch CSRF token before making requests
instance.interceptors.request.use(
  async (config) => {
    // Only fetch CSRF token for non-GET requests that are not login or signup
    if (config.method !== 'get' && !config.url.endsWith('/login') && !config.url.endsWith('/signup')) {
      try {
        const response = await axios.get(`${API_BASE_URL}/csrf-token`, { withCredentials: true });
        config.headers['X-CSRF-Token'] = response.data.csrfToken;
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
      }
    }
    console.log("Request headers:", config.headers);
    return config;
  },
  (error) => {
    console.error("Request interceptor error:", error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    console.log("Response status:", response.status);
    console.log("Response headers:", response.headers);
    return response;
  },
  (error) => {
    console.error("Response error:", error.response ? error.response.data : error.message);
    return Promise.reject(error);
  }
);

export default instance;