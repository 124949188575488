import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Save, X } from 'lucide-react';
import profilePicture from '../../assets/images/test-user.png';
import '../../styles/components/Profile.scss';

const ProfileForm = ({ profile, onSubmit, onCancel }) => {
  const [username, setUsername] = useState(profile.username);
  const [email, setEmail] = useState(profile.email);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!username.trim()) newErrors.username = 'Username is required';
    if (!email.trim()) newErrors.email = 'Email is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit({ username, email });
    }
  };

  return (
    <form className="profile-card" onSubmit={handleSubmit}>
      {/* TODO: get this prom user table */}
      <img className="profile-image" src={profilePicture} alt="Profile" />
      <div className="profile-details">
        <div className="form-field">
          <label htmlFor="username">Username</label>
          <input
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          {errors.username && <span className="error">{errors.username}</span>}
        </div>
        <div className="form-field">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>
      </div>
      <div className="form-actions">
        <button type="button" onClick={onCancel} className="cancel-button">
          <X size={18} />
          <span>Cancel</span>
        </button>
        <button type="submit" className="save-button">
          <Save size={18} />
          <span>Save</span>
        </button>
      </div>
    </form>
  );
};

ProfileForm.propTypes = {
  profile: PropTypes.shape({
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ProfileForm;