import { API_BASE_URL } from '../config';
import api from './axiosConfig';

export const createProfile = async (data) => {
  return api.post(`${API_BASE_URL}/profile/`, data)
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const updateProfile = async (data, userId) => {
  return api.put(`${API_BASE_URL}/users/${userId}`, data)
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
};

// TODO: not in use
export const getProfile = async (userId) => {
  return api.get(`${API_BASE_URL}/profile/${userId}`)
};