import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import ProfilePage from '../pages/ProfilePage';
import ExercisePage from '../pages/ExercisePage';
import NutritionPage from '../pages/NutritionPage';
import SignUpPage from '../pages/auth/SignUpPage';
import LoginPage from '../pages/auth/LoginPage';
import ProtectedRoute from '../components/ProtectedRoute';
import GuestsRoute from '../components/GuestsRoute';
import DashboardPage from '../pages/DashboardPage';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<GuestsRoute><HomePage /></GuestsRoute>} />
    <Route path="/signup" element={<GuestsRoute><SignUpPage /></GuestsRoute>} />
    <Route path="/login" element={<GuestsRoute><LoginPage /></GuestsRoute>} />
    <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>}/>
    <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>}/>
    <Route path="/exercise/*" element={<ProtectedRoute><ExercisePage /></ProtectedRoute>}/>
    <Route path="/nutrition" element={<ProtectedRoute><NutritionPage /></ProtectedRoute>}/>
    <Route path="*" element={<div>404 Not Found</div>} />
  </Routes>
);

export default AppRoutes;