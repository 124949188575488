// src/components/NavigationMenu.js

import React, { useState, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Menu, X, Home, User, Dumbbell, Apple, LogOut, LogIn, UserPlus } from 'lucide-react';
import ThemeToggle from '../ThemeToggle';
import profilePicture from '../../assets/images/test-user.png';
import '../../styles/components/NavigationMenu.scss';
import { AuthContext } from '../../contexts/AuthContext';
import axiosInstance from '../../api/axiosConfig';

const NavigationMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { auth, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleLogout = async () => {
    try {
      await axiosInstance.post('/users/logout');
      setAuth({
        isAuthenticated: false,
        user: null,
        loading: false,
      });
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Define menu items for authenticated users
  const authMenuItems = [
    { to: "/dashboard", icon: <Home size={20} />, text: "Dashboard" },
    { to: "/profile", icon: <User size={20} />, text: "Profile" },
    { to: "/exercise", icon: <Dumbbell size={20} />, text: "Exercise" },
    { to: "/nutrition", icon: <Apple size={20} />, text: "Nutrition" },
    { to: "/logout", icon: <LogOut size={20} />, text: "Logout", onClick: handleLogout },
  ];

  // Define menu items for non-authenticated users
  const guestMenuItems = [
    { to: "/", icon: <Home size={20} />, text: "Home" },
    { to: "/login", icon: <LogIn size={20} />, text: "Login" },
    { to: "/signup", icon: <UserPlus size={20} />, text: "Sign Up" },
  ];

  const menuItems = auth.isAuthenticated ? authMenuItems : guestMenuItems;

  return (
    <>
      <div className="mobile-header">
        <button className="menu-toggle" onClick={toggleMenu}>
          <Menu size={24} />
        </button>
        {
          auth.isAuthenticated ?
            <NavLink to="/" className="nav-logo">
              <img src={profilePicture} alt='Logo' />
            </NavLink> : null
        }
        <ThemeToggle />
      </div>
      <nav className={`nav-menu ${isOpen ? 'open' : ''}`}>
        <div className="nav-header">
          {
            auth.isAuthenticated ?
              <NavLink to="/" className="nav-logo">
                <img src={profilePicture} alt='Logo' />
              </NavLink> : null
          }
          <button className="menu-toggle" onClick={toggleMenu}>
            <X size={24} />
          </button>
        </div>
        <ul className="nav-links">
          {menuItems.map((item) => (
            <li key={item.to}>
              {item.to === "/logout" ? (
                <button onClick={item.onClick} className="nav-button">
                  {item.icon}
                  <span>{item.text}</span>
                </button>
              ) : (
                <NavLink to={item.to} onClick={() => setIsOpen(false)}>
                  {item.icon}
                  <span>{item.text}</span>
                </NavLink>
              )}
            </li>
          ))}
        </ul>
        <div className="nav-footer">
          <ThemeToggle />
        </div>
      </nav>
    </>
  );
};

export default NavigationMenu;
