import React, { useContext, useState, useEffect } from 'react';
import ProfileInfo from '../components/profile/ProfileInfo';
import ProfileForm from '../components/profile/ProfileForm';
import { updateProfile } from '../api/profileApi';
import { API_BASE_URL } from '../config';
import { AuthContext } from '../contexts/AuthContext';
import api from '../api/axiosConfig';
import '../styles/pages/ProfilePage.scss';

const ProfilePage = () => {
  const { auth } = useContext(AuthContext);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setIsLoading(true);
        const response = await api.get(`${API_BASE_URL}/users/${auth.user.id}`);
        // TODO: divide between user and profile
        setProfile({username: response.data.username, email: response.data.email, ...response.data.profile});
      } catch (error) {
        console.error('Error fetching user profile', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, [auth.user.id]);

  // TODO: abstract
  const fetchProfile = async () => {
    try {
      setIsLoading(true);
      const response = await api.get(`${API_BASE_URL}/users/${auth.user.id}`);
      // TODO: divide between user and profile
      setProfile({username: response.data.username, email: response.data.email, ...response.data.profile});
    } catch (error) {
      console.error('Error fetching user profile', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (updatedData) => {
    try {
      setIsLoading(true);
      await updateProfile(updatedData, auth.user.id);
      await fetchProfile();
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <div className="profile-page">Loading...</div>;
  if (!profile) return <div className="profile-page">Error loading profile</div>;

  return (
    <div className="profile-page">
      <h1>Welcome {profile.firstname} {profile.lastname}</h1>
      {isEditing ? (
        <ProfileForm
          profile={profile}
          onSubmit={handleSubmit}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <ProfileInfo
          profile={profile}
          onEdit={() => setIsEditing(true)}
        />
      )}
    </div>
  );
};

export default ProfilePage;